import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext'
import $ from 'jquery';
import { Button, Modal } from "react-bootstrap";
import { Request } from '../../../scripts/Request';
import { Currency } from '../../../scripts/StringUtils';
import InfiniteScroll from "react-infinite-scroll-component";
import { UploadService } from '../../../scripts/UploadService';
import { useNavigate } from 'react-router-dom';
import './Lista.css';


export const Lista = () => {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState({ word: "", categoria_id: "" });
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);

    const navigate = useNavigate();

    const listRef = useRef();
    const pageRef = useRef();
    const totalRef = useRef();
    const usersPrestineRef = useRef(false);


    //#region Effects
    useEffect(() => {
        if(user.token !== "" && !usersPrestineRef.current){
            Init();
        }
    }, [user]);


    useEffect(() => {
        if(isLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [isLoading]);
    //#endregion Effects


    //#region Loaders
    const Init = async() => {
        if(!usersPrestineRef.current){
            usersPrestineRef.current = true;
            _HandlerSearchChange();
        }
    }
    //#endregion Loaders


     //#region Handlers
     const _HandlerChange = (event) => {
        let _search = Object.assign({}, search);
        _search[event.target.id] = event.target.value;
        setSearch(_search);
    }


    const _HandlerSearchChange = (event) => {
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };

        listRef.current = [];
        setList(listRef.current);
        setIsLoading(true);
        setHasMore(true);

        Promise.resolve(new Request().Run("api/Banners/List?page=" + pageRef.current.page + "&interval=50&offset=" + pageRef.current.offset, "POST", { word: search.word }, user.token))
            .then((data) => {
                setTotal(data.total);
                totalRef.current = data.total;
                let _list = [...data.list];
             
                listRef.current = listRef.current.concat(_list);
                setList(listRef.current);
                setIsLoading(false);
                if (listRef.current.length >= totalRef.current) {
                    setHasMore(false);
                } 
            });
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setIsLoading(true);

            Promise.resolve(new Request().Run("api/Banners/List?page=" + pageRef.current.page + "&interval=50&offset=" + pageRef.current.offset, "POST", { word: search.word}, user.token))
                .then((data) => {
                    let _list = [...data.list];
                    listRef.current = listRef.current.concat(_list);
                    setList(listRef.current);
                    setIsLoading(false);
                });
        } catch (e) { }
    }


    const _UserClick = (item) => {
        navigate('/Banners/Cadastro/'+item.banner_id);
    }


    const _HandleCheck = (event, banner) => {
        //console.log(event, banner);

        let _list = [...list];
        let _item = _list.find(item => item.banner_id===banner.banner_id)
        _item.ativo = _item.ativo === 1 ? 0 : 1;

        Promise.resolve(new Request().Run("api/Banners/Ativo", "POST", { banner_id: banner.banner_id, ativo: _item.ativo}, user.token))
        .then((data) => {
           
        });
        setList(_list);
    }


    const _DisableUser = async(item) => {
        window.swal({
            title: "Tem certeza?",
            text: "Deseja realmente desativar esse produto?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Sim, tenho certeza!',
            cancelButtonText: "Não, cancelar!",
            closeOnConfirm: true,
            closeOnCancel: true
         },
         function(isConfirm){
            if (isConfirm){
                setIsLoading(true);
                Promise.resolve(new Request().Run("api/Banner/Ativo", "POST", { produto_id: item.produto_id, ativo: (item.ativo===0?1:0) }, user.token))
                        .then((data) => {
                            _HandlerSearchChange();
                            setIsLoading(false);
                        }).catch(()=> {
                            setIsLoading(false);
                        });
            } else {
               
            }
         });

        
    }
    //#endregion Handlers



    return (
        <div className="BannersLista">
            <div className="row">
                <div className="col-lg-12">
                    <div className="ibox float-e-margins">
                        <div className="ibox-title">
                            <h5>Lista de Banners</h5>
                            <span className='pull-right'>
                            <label className="label label-info btn"> { total } banners </label>
                            </span>
                        </div>

                        <div className="ibox-content">
             

                            <div>
                                <InfiniteScroll
                                    dataLength={list.length}
                                    next={_fetchMoreData}
                                    hasMore={hasMore}
                                
                                    style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                                    endMessage={
                                        <p style={{ textAlign: "center" }}>
                                            <b>Não há mais dados</b>
                                        </p>
                                    }>


                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Titulo</th>
                                                <th>Descricao</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.map((item, i) => (
                                                <tr key={'user' + i} className={(item.ativo===1?'':'risk')}>
                                                    <td><input type="checkbox" className="checkbox" checked={item.ativo===1} onClick={(event) => _HandleCheck(event, item)}/></td>
                                                    <td onClick={() => _UserClick(item)}> {item.titulo} </td>
                                                    <td onClick={() => _UserClick(item)}> {item.descricao} </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
}
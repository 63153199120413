import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { Context } from '../../../Context/AuthContext'
import $ from 'jquery';
import { Button, Modal } from "react-bootstrap";
import { Request } from '../../../scripts/Request';
import { FormGenerator } from '../../Form/FormGenerator';
import { useNavigate, useParams } from 'react-router-dom';
import { UploadService } from '../../../scripts/UploadService';
import { FilterObject } from '../../../scripts/ArrayUtils';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import './Cadastro.css';


export const Cadastro = (props) => {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [bannerId, setBannerId] = useState("");
    const [banner, setBanner] = useState({edited: 0});
    const [selectedImage, setSelectedImage] = useState("");

    const bannerFileInput = useRef(null);
    const _formBannerRef = useRef();
    const _formLoadedRef = useRef();
    const _bannerDataRef = useRef();
    const _bannerIdRef = useRef();
    const _userRef = useRef(user);

    const [image, setImage] = useState("");
    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState();
    const onChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            let _banner = Object.assign(banner);
            _banner.edited = 0;
            setBanner(_banner);
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };
    const navigate = useNavigate();

    let { BannerId } = useParams();

    
    //#region Effects
    useEffect(() => {
        if (BannerId) {
            _bannerIdRef.current = BannerId;
            setBannerId(BannerId);
        }

        _userRef.current = user;
    }, [user]);


    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);

    //#endregion Effects


    //#region Form Handlers
     const OnFormLoaded = (fields) => {
        try {
            let wait_token = setInterval(async () => {
                if (_userRef.current.token !== "" && _formBannerRef.current !== undefined) {
                    clearInterval(wait_token);

                    if (_bannerIdRef.current !== "" && _bannerIdRef.current !== undefined && _bannerIdRef.current != null) {
                        let banner_data = await new Request().Run("api/Banners/Load?banner_id=" + _bannerIdRef.current, "POST", {}, _userRef.current.token);
                        _bannerDataRef.current = banner_data;
                        setBanner(banner_data);
                        await _formBannerRef.current.Load(banner_data);

                        if(banner_data.img !== "") {
                            _handleEditFoto({foto: banner_data.img});
                        }
                    } 
                }
            }, 100);
        } catch (e) {
            console.log(e);
        }

    }


    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _handlerUploadProdClick = () => {
        bannerFileInput.current.click();
    }

    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();
        setPageLoading(true);
 

        let _result = await _formBannerRef.current.Post("api/Banners/Save", { banner_id: BannerId}, user.token);
        setPageLoading(false);

        if (_result.toString() === "exists") {
            alert("Nome já cadastrado!");
        } else {
            navigate('/Banners/Lista');
        }
    }
    //#endregion Handlers


    //#region Cropper
    const _handleEditFoto = (foto) => {
        setSelectedImage(foto);

        let request = new XMLHttpRequest();
        request.open('GET', "https://painel.comercialwn.com.br/db/"+foto.foto, true);
        request.responseType = 'blob';
        request.onload = function() {
            
            let reader = new FileReader();
            reader.onload =  function(e){
                console.log(e.target.result)
                setImage(e.target.result);
            };
            reader.readAsDataURL(request.response);
        };
        request.send();
    }

    const getCropData = (foto) => {
        if (typeof cropper !== "undefined") {
            foto = foto.replace("https://painel.comercialwn.com.br/db/", "");

            setPageLoading(true);

            let _banner = Object.assign(banner);
            _banner.edited = 1;
            setBanner(_banner);

            let cropped = cropper.getCroppedCanvas({ 
                width: 685,
                height: 607,
                minWidth: 685,
                minHeight: 607,
                maxWidth: 685,
                maxHeight: 607,
                fillColor: '#fff',
                imageSmoothingEnabled: true,
                imageSmoothingQuality: 'high'});
            setCropData(cropper.getCroppedCanvas().toDataURL());

        
            let blob = cropped.toBlob(async function (blob) {
                let file = new File([blob], foto, { type: 'image/jpeg' });
                let upload = new UploadService();
                upload.Data = {foto: foto}
                upload.OnEnd = (image) => {
                    setPageLoading(false);
                }
                await upload.Upload(file, "https://painel.comercialwn.com.br/api/Banners/Crop", user.token);
            }, 'image/jpeg');
        }
    }
    //#endregion Cropper

    return (
        <div className="BannersCadastro">
            <div>
                <form name="fuser" onSubmit={(event) => _HandleSubmit('banner', event)} autoComplete="off">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <h5>Cadastro de Banner</h5>
                                </div>
           

                                <div className="ibox-content">
                                    <div className="row m-b-lg btngrp">
                                        <FormGenerator ref={_formBannerRef} name={'banner'} url={'/forms/banner.json'} execRef={Exec}></FormGenerator>
                                        <br />

                                        <div className="row">
                                            <div className="col-sm-8" style={{overflow:'hidden'}}>
                                                <Cropper
                                                    style={{ height: 500, width: "100%" }}
                                                    zoomTo={1}
                                                    initialAspectRatio={685/607}
                                                    aspectRatio={685/607}
                                                    preview=".img-preview"
                                                    src={image}
                                                    scaleX={1}
                                                    scaleY={1}
                                                    viewMode={1}
                                                    minCropBoxHeight={10}
                                                    minCropBoxWidth={10}
                                                    background={false}
                                                    responsive={true}
                                                    autoCropArea={1}
                                                    checkOrientation={false}
                                                    onInitialized={(instance) => {
                                                        setCropper(instance);
                                                    }}
                                                    guides={true}
                                                    />
                                            
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="box" style={{ width: "342px", height: "303px" }}>
                                                    <div id="foto" className="img-preview" style={{ width: "342px", height: "303px"}} />
                                                </div>

                                                <hr style={{ width: '250px'}} />  
                                                <button type="button" className="btn btn-primary" onClick={() => getCropData(selectedImage.foto)} style={{ width: '250px'}} disabled={banner.edited===1}> Salvar Recorte (685x607)</button>
                                                <hr style={{ width: '250px'}}/>   
                                                <input type="file" onChange={onChange} ref={bannerFileInput} style={{display: 'none'}} accept=".jpg"/>
                                                <button type="button" className='btn btn-info' onClick={_handlerUploadProdClick} style={{width:'250px'}}>Enviar nova foto</button>
                                                <hr style={{ width: '250px'}}/>       
                                                <button className="btn btn-primary" title="Salvar Banner" type="submit" style={{width:'250px'}} disabled={banner.edited===0}><i className="fa fa-check"></i> Salvar Banner</button>
                                            </div>
                                        </div>
                                      
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    )
}
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext'
import $ from 'jquery';
import { Request } from '../../../scripts/Request';
import { Button } from "react-bootstrap";
import { HubObjects } from '../../../scripts/HubObjects';
import { FormGenerator } from '../../Form/FormGenerator';
import './index.css';

import ResumeTop from './ResumeTop';
import ResumeProdutos from './ResumeProdutos';
import ResumeAtividades from './ResumeAtividades';
import ResumeClients from './ResumeClients';



export default function Home() {
    const component_name = "admin/home";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [isLoading, setIsLoading] = useState(false);
 
    const _userRef = useRef();
    const timerIdleRef = useRef(new Date());
    const loadedRef = useRef(false);

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);


    //#region Effects
    useEffect(() => {
        let timer = setTimeout(() => { Init() }, 1000);

        return () => {
            clearTimeout(timer);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);

    useEffect(() => {
        if (user.token !== "") { 
            _userRef.current = user;  
        }
    }, [user]);


    useEffect(() => {
        if(isLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [isLoading]);

    //#endregion Effects


    //#region Init 
    const Init = () => {
        if(!loadedRef.current){
            loadedRef.current = true; 
        }    
     }
    //#endregion Init


    //#region Loaders
   
    //#endregion Loaders



    //#region Handlers

    //#endregion Handlers


    //#region HubObjects Handlers

    //#endregion HubObjects Handlers


    //#region Modal

    //#endregion Modal




    //#region Functions 
 
    //#endregion Functions




    return (
        <div>
            <ResumeTop />
            <div className="row">
                <ResumeProdutos />
                <ResumeClients />
                <ResumeAtividades />
            </div>
        </div>
    )
}
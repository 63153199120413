import { AppServer } from './Loadbalancer';


export const RequestPost = async (args, url, auth) => {
    const controller = new window.AbortController();
    const signal = controller.signal;
    if(url.toLowerCase().indexOf("http") === -1 && url.substr(0,1) !== "/" && url.substr(0,2) !== "./" ) url =  AppServer() + url;

    const rawResponse = await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            auth
        },
        signal: signal,
        body: JSON.stringify(args)
    });
    const content = await rawResponse.json();
    return content;
}


export const RequestGet = async (url) => {
    const controller = new window.AbortController();
    const signal = controller.signal;
    if(url.toLowerCase().indexOf("http") === -1 && url.substr(0,1) !== "/" && url.substr(0,2) !== "./" ) url = AppServer() + url;

    return await fetch(url, { method: 'GET', signal: signal })
        .then(response => response.arrayBuffer())
        .then(buffer => {
            let decoder = new TextDecoder("utf-8");
            let text = decoder.decode(buffer);
            return JSON.parse(text);
        });


}


export class Request {
    constructor() {
        this.state = { loading: false };
        this.Aborter();
    }

    Aborter = () => {
        this.controller = new window.AbortController();
        this.signal = this.controller.signal;
    }

    Run = async (url, method = 'GET', args, authtoken = "", ttl = 0) => {
        this.Aborter();
        this.url = url;
        this.method = method;
        this.args = args || {};
        if(this.url.toLowerCase().indexOf("http") === -1 && this.url.substr(0,1) !== "/" && this.url.substr(0,2) !== "./" ) this.url = AppServer() + this.url;

        //console.log(this.url);

        if(ttl > 0) {
            this.cache = localStorage.getItem(this.url);
            try {
                let _cache = JSON.parse(this.cache);
                if(new Date().getTime() - _cache.milliseconds < ttl) {
                    //console.log("from cache",_cache.data);
                    return _cache.data;
                } else {
                    //console.log("cache renew", _cache.data);
                }
            } catch(e) {

            }
        }
        
        this.state.loading = true;
        if (this.method.toLocaleUpperCase() === "POST") {
            return await fetch(this.url, {
                method: this.method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': authtoken
                },
                signal: this.signal,
                body: JSON.stringify(this.args)
            }).then(async response => {
                this.state.loading = false;
                let r = await response.json();
                try {
                   // if(r.status===401) window.location.href="/login";
                } catch(e) { }
                if(ttl > 0) {
                    localStorage.setItem(this.url, JSON.stringify({data: r, milliseconds: new Date().getTime()}));
                }
                return r;
            }).catch((e) => {
                console.log("cache clear", e);
                localStorage.clear();
            });
        } else {
            return await fetch(this.url, {
                method: 'GET', headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': authtoken
                }, signal: this.signal
            })
            /*.then(response => response.json())
            .then(data => { 
                console.log(data);
                this.state.loading = false;
                return data;
             })*/
            .then(response => response.arrayBuffer())
            .then(buffer => {
                let decoder = new TextDecoder("utf-8");
                let text = decoder.decode(buffer);
                this.state.loading = false;
                let r = JSON.parse(text);
                try {
                    //if(r.status===401) window.location.href="/login";
                } catch(e) { }
                if(ttl > 0) localStorage.setItem(this.url, JSON.stringify({data: r, milliseconds: new Date().getTime()}));
                return r;
            }).catch(() => {
                localStorage.clear();
            });
        }
    }

    Abort = () => {
        console.log("Aborting ", this.state.loading);
        //if (this.state.loading) {
        this.controller.abort();
        this.state.loading = false;
        //}
    }

    State = () => {
        return this.state;
    }
}


export class RequestRetry {
    constructor() {
        this.state = { loading: false };
        this.Aborter();
        this.try = 5;
    }

    Aborter = () => {
        this.controller = new window.AbortController();
        this.signal = this.controller.signal;
    }

    Run = async (url, method = 'GET', args) => {
        this.Aborter();
        this.url = url;
        this.method = method;
        this.args = args || {};
        if(this.url.toLowerCase().indexOf("http") === -1 && this.url.substr(0,1) !== "/" && this.url.substr(0,2) !== "./" ) this.url = AppServer() + this.url;

        this.state.loading = true;
        if (this.method.toLocaleUpperCase() === "POST") {
            return await fetch(this.url, {
                method: this.method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                signal: this.signal,
                body: JSON.stringify(this.args)
            }).then(response => {
                this.try = 5;
                if (this.state.loading) return response.json();
                this.state.loading = false;
            }).catch(() => {
                this.try--;
                if (this.try > 0) {
                    if (this.state.loading) return this.Run(this.url, this.method, this.args);
                } else {
                    return null;
                }
            });
        } else {
            return await fetch(url, { method: 'GET', headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                    }, 
                    signal: this.signal
                })
                .then(response => response.json())
                .then(data => { 
                    console.log(data);
                    this.state.loading = false;
                    return data;
                 })
                /*.then(response => response.arrayBuffer())
                .then(buffer => {
                    let decoder = new TextDecoder("iso-8859-1");
                    let text = decoder.decode(buffer);
                    if (this.state.loading) return JSON.parse(text);
                    this.state.loading = false;
                })*/.catch(() => {
                    this.try--;
                    if (this.try > 0) {
                        if (this.state.loading) return this.Run(this.url, this.method, this.args);
                    } else {
                        return null;
                    }
                });
        }
    }

    Abort = () => {
        console.log("Aborting ", this.state.loading);
        this.state.loading = false;
        this.controller.abort();
    }

    State = () => {
        return this.state;
    }
}


/*export const RequestPost = async (args, url) => {
    const rawResponse = await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(args)
    });
    const content = await rawResponse.json();
    return content;
}


export const RequestGet = async (url) => {
    return await fetch(url)
        .then(response => response.arrayBuffer())
        .then(buffer => {
            let decoder = new TextDecoder("utf-8");
            let text = decoder.decode(buffer);
            return JSON.parse(text);
        });
}*/
import React, { useEffect,  useState, useRef, Suspense, lazy, useContext } from 'react';
import { Routes, Route,  useNavigate, useLocation, useParams } from 'react-router-dom';
import { Context } from './Context/AuthContext';
import Login  from './components/Login/Login';
import Home from './components/Admin/Home';
import { Lista as UsuariosLista }  from './components/Admin/Usuarios/Lista';
import { Cadastro as UsuariosCadastro }  from './components/Admin/Usuarios/Cadastro';
import { Lista as ProdutosLista }  from './components/Admin/Produtos/Lista';
import { Cadastro as ProdutosCadastro }  from './components/Admin/Produtos/Cadastro';
import { Lista as BannersLista }  from './components/Admin/Banners/Lista';
import { Cadastro as BannersCadastro }  from './components/Admin/Banners/Cadastro';
import { Lista as ClientesLista }  from './components/Admin/Clientes/Lista';
import { Cadastro as ClientesCadastro }  from './components/Admin/Clientes/Cadastro';
import Layout from './Layout';



export default function RouteManager() {
    const location = useLocation();
    const isLogin = (location.pathname.toLocaleLowerCase().indexOf("login") > -1 || location.pathname === "/");
    const navigate = useNavigate();
    const routeRef = useRef("");

    const ProtectedRoute = ({ children }) => {
        const { loading, authenticated } = useContext(Context);
        if (!authenticated && !loading && !isLogin) {
           navigate("/login");
        } else {
            return children;
        }

      };


    return (
    
      <Suspense fallback={<div>Carregando...</div>}>
        <Layout>
            <Routes>
                <Route exact path="/" element={<Login />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/Home" element={<ProtectedRoute ><Home /></ProtectedRoute>} />
           
                <Route exact path="/Produtos/Lista" element={<ProtectedRoute><ProdutosLista /></ProtectedRoute>} />
                <Route exact path="/Produtos/Cadastro" element={<ProtectedRoute><ProdutosCadastro /></ProtectedRoute>} />
                <Route exact path="/Produtos/Cadastro/:ProdutoId" element={<ProtectedRoute><ProdutosCadastro /></ProtectedRoute>} />


                <Route exact path="/Banners/Lista" element={<ProtectedRoute><BannersLista /></ProtectedRoute>} />
                <Route exact path="/Banners/Cadastro/:BannerId" element={<ProtectedRoute><BannersCadastro /></ProtectedRoute>} />

                <Route exact path="/Clientes/Lista" element={<ProtectedRoute><ClientesLista /></ProtectedRoute>} />
                <Route exact path="/Clientes/Cadastro/:ClienteId" element={<ProtectedRoute><ClientesCadastro /></ProtectedRoute>} />

                <Route exact path="/Usuarios/Lista" element={<ProtectedRoute><UsuariosLista /></ProtectedRoute>} />
                <Route exact path="/Usuarios/Cadastro" element={<ProtectedRoute><UsuariosCadastro /></ProtectedRoute>} />
                <Route exact path="/Usuarios/Cadastro/:UsuarioId" element={<ProtectedRoute><UsuariosCadastro /></ProtectedRoute>} />
            </Routes>
        </Layout>
      </Suspense>
   
  );
}
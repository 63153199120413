/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { Context } from '../../Context/AuthContext';
import { HubObjects } from '../../scripts/HubObjects';
import { Request } from '../../scripts/Request';
import { Button } from "react-bootstrap";
import $ from 'jquery';
import moment from 'moment';
import './Header.css';




export default function Header() {
    const component_name = "Header";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [menubar, setMenubar] = useState(false);
    const [state, setState] = useState({
        voiceActivity: false,
        zoom: 100
    });


    const timerIdleRef = useRef(new Date());
    const firstLoadRef = useRef(false);


    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);


    //#region Effects
    useEffect(() => {

        if(!firstLoadRef.current) {
            firstLoadRef.current = true;
        }
        

        return () => {
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    HubObjects.Clear(component_name);
                } catch(e) {}
            } 
        }
    }, []);


    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);
    //#endregion Effects


    //#region Loaders
   
    //#endregion Loaders


    //#region Handlers
    const _FullScreen = () => {
        if (!$("body").is(":fullscreen")) {
            document.body.requestFullscreen();
        } else {
            document.exitFullscreen();
        }
    }


    const _HideMenuClick = () => {
        if (!menubar) {
            $("body").addClass("mini-navbar");
            setMenubar(true);
        } else {
            $("body").removeClass("mini-navbar");
            setMenubar(false);
        }
    }


    const _ChangeMicrophone = () => {

    }


    const _Exit = () => {
        handleLogout();
    }
    //#endregion Handlers


    //#region HubObjects
  
    //#endregion HubObjects




    //#region Functions
   
    //#endregion Functions



    return (<nav className="navbar navbar-static-top Header" role="navigation">
        <div className="navbar-header">
            <a className="navbar-minimalize minimalize-styl-2 btn btn-primary" onClick={_HideMenuClick} style={{ width: '40px' }}><i className="fa fa-bars"></i></a>
        </div>


        <ul className="nav navbar-top-links navbar-right">
            <li>
                <a className="right-sidebar-toggle" onChange={_ChangeMicrophone}>
                    <i className={'fa fa-microphone' + (state.voiceActivity ? ' ' : ' hide')}></i>
                    <i className={'fa fa-microphone-slash' + (!state.voiceActivity ? ' ' : ' hide')}></i>
                </a>
            </li>

            <li>
                <a>
                    <span className="m-r-sm text-muted welcome-message">ComercialWN v1.0</span>
                </a>
            </li>

            <li>
                <a onClick={_FullScreen}>
                    <i className="fa fa-external-link"></i>
                </a>
            </li>

            <li>
                <a onClick={_Exit}>
                    <i className="fa fa-sign-out"></i> Sair
                </a>
            </li>
        </ul>

       
    </nav>);
}
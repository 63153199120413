import React from 'react';
import RouteManager from './RouteManager';
import { BrowserRouter as Router} from 'react-router-dom';

import { AuthProvider } from './Context/AuthContext';

const App = () => (
  <Router>
    <AuthProvider>
      <RouteManager />
    </AuthProvider>
  </Router>
);

export default App;

import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext'
import $ from 'jquery';
import { Button, Modal } from "react-bootstrap";
import { Request } from '../../../scripts/Request';
import { Currency } from '../../../scripts/StringUtils';
import InfiniteScroll from "react-infinite-scroll-component";
import { UploadService } from '../../../scripts/UploadService';
import { useNavigate } from 'react-router-dom';
import './Lista.css';


export const Lista = () => {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState({ word: "", ativo: ""});
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const navigate = useNavigate();

    const listRef = useRef();
    const pageRef = useRef();
    const totalRef = useRef();
    const usersPrestineRef = useRef(false);


     //#region Effects
     useEffect(() => {
        if(user.token !== "" && !usersPrestineRef.current){
            Init();
        }
    }, [user]);


    useEffect(() => {
        if(isLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [isLoading]);
    //#endregion Effects


    //#region Loaders
    const Init = async() => {
        if(!usersPrestineRef.current){
            usersPrestineRef.current = true;
            _HandlerSearchChange(null);
        }
    }
    //#endregion Loaders


    //#region Handlers
    const _HandlerChange = (event) => {
        let _search = Object.assign({}, search);
        _search[event.target.id] = event.target.value;
        setSearch(_search);
    }


    const _HandlerSearchChange = (event) => {
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };

        listRef.current = [];
        setList(listRef.current);
        setIsLoading(true);
        setHasMore(true);

        Promise.resolve(new Request().Run("api/Clientes/List?page=" + pageRef.current.page + "&interval=50&offset=" + pageRef.current.offset, "POST", {word: search.word, ativo: search.ativo}, user.token))
            .then((data) => {
                setTotal(data.total);
                totalRef.current = data.total;
                let _list = [...data.list];
                listRef.current = listRef.current.concat(_list);
                setList(listRef.current);
                setIsLoading(false);
                if (listRef.current.length >= totalRef.current) {
                    setHasMore(false);
                } 
            });
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setIsLoading(true);

            Promise.resolve(new Request().Run("api/Clientes/List?page=" + pageRef.current.page + "&interval=50&offset=" + pageRef.current.offset, "POST", { word: search.word, ativo: search.ativo }, user.token))
                .then((data) => {
                    let _list = [...data.list];
                    listRef.current = listRef.current.concat(_list);
                    setList(listRef.current);
                    setIsLoading(false);
                });
        } catch (e) { }
    }


    const _UserClick = (item) => {
        navigate('/Clientes/Cadastro/'+item.cliente_id);
    }


    const _DisableUser = async(item) => {
        window.swal({
            title: "Tem certeza?",
            text: "Deseja realmente " + (item.ativo===0?'ativar':'desativar') +" esse cliente?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Sim, tenho certeza!',
            cancelButtonText: "Não, cancelar!",
            closeOnConfirm: true,
            closeOnCancel: true
         },
         function(isConfirm){
            if (isConfirm){
                setIsLoading(true);
                Promise.resolve(new Request().Run("api/Clientes/Ativo", "POST", { cliente_id: item.cliente_id, ativo: (item.ativo===0?1:0) }, user.token))
                        .then((data) => {
                            _HandlerSearchChange();
                            setIsLoading(false);
                        }).catch(()=> {
                            setIsLoading(false);
                        });
            } else {
               
            }
         }); 
    }


    //#endregion Handlers



    return (
        <div className="ClientesLista">
            <div className="row">
                <div className="col-lg-12">
                    <div className="ibox float-e-margins">
                        <div className="ibox-title">
                            <h5>Lista de Clientes</h5>
                            <span className='pull-right'>
                            <label className="label label-info btn"> { total } clientes </label>
                            </span>
                        </div>

                        <div className="ibox-content">
                            <div className="input-group">
                                <input type="text" id="word" placeholder="Buscar por nome" value={search.word} className="input form-control" onChange={_HandlerChange} />

        
                                <span className="input-group-btn">
                                    <select id="ativo" className="input form-control" value={search.ativo} onChange={_HandlerChange}>
                                        <option value="">Todos</option>
                                        <option value="1">Ativos</option>
                                        <option value="0">Inativos</option>
                                    </select>
                                </span>

                                <span className="input-group-btn">
                                    <button id="btnBusca" type="button" className="btn btn btn-primary" onClick={_HandlerSearchChange}> <i className="fa fa-search"></i> Busca</button>
                                </span>
                            </div>

                            <div>
                                <InfiniteScroll
                                    dataLength={list.length}
                                    next={_fetchMoreData}
                                    hasMore={hasMore}
                                    loader={
                                        <p style={{ textAlign: "center" }}>
                                            <b>Carregando...</b>
                                        </p>
                                    }
                                    style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                                    endMessage={
                                        <p style={{ textAlign: "center" }}>
                                            <b>Não há mais dados</b>
                                        </p>
                                    }>


                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th>Cnpj</th>
                                                <th>Whatsapp</th>
                                                <th>Email</th>
                                                <th>Telefone</th>
                                                <th>Cidade</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.map((item, i) => (
                                                <tr key={'user' + i} className={(item.ativo===1?'':'risk')}>
                                                    <td onClick={() => _UserClick(item)}> {item.nome} </td>
                                                    <td onClick={() => _UserClick(item)}> {item.cnpj} </td>
                                                    <td onClick={() => _UserClick(item)}> {item.whatsapp} </td>
                                                    <td onClick={() => _UserClick(item)}> {item.email} </td>
                                                    <td onClick={() => _UserClick(item)}> {item.telefone} </td>
                                                    <td onClick={() => _UserClick(item)}> {item.cidade}-{item.uf} </td>
                                                    <td style={{ padding: '4px', margin: 0, width: '35px' }}><button className="btn btn-white btn-sm" type="button" onClick={() => _DisableUser(item)} style={{ margin: '1px', padding: '1px', paddingLeft: '7px', paddingRight: '7px' }}><i className={'fa ' + (item.ativo===1?'fa-trash':'fa-user') }></i></button></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
}
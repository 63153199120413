import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Request } from '../../../../scripts/Request';
import { Context } from '../../../../Context/AuthContext';
import { Currency } from '../../../../scripts/StringUtils';
import './index.css';


export default function ResumeTop() {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [isLoading, setIsLoading] = useState(false);
    const [info, setInfo] = useState({clientes_mes: 0, clientes_mes_p: 0, clientes_total: 0,  produtos_ativos: 0,  produtos_inativos: 0, clientes_old: 0});

    const _userRef = useRef();
    const timerIdleRef = useRef(new Date());
    const loadedRef = useRef(false);

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);


    //#region Effects
    useEffect(() => {
        let timer = setTimeout(() => { Init() }, 200);

        return () => {
            clearTimeout(timer);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 200) {
                try {
                    //HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(isLoading){
            window.w_loadObj("#ResumeTop");
        } else {
            window.w_unloadObj("#ResumeTop");
        }
    }, [isLoading]);

    //#endregion Effects


    //#region Init
    const Init = () => {
        if(!loadedRef.current) {
            loadedRef.current = true;

            let wait_token = setInterval(()=> {
                if(user.token !== "")  {
                    clearInterval(wait_token);
                    LoadResumeTop();
                }
            }, 100);
        }
    }
    //#endregion Init



    //#region Loaders
    const LoadResumeTop = (force = false) => {
        setIsLoading(true);

        Promise.resolve(new Request().Run("api/Home/ResumeTop", "POST", {force: force}, user.token, 0*1000))
        .then((data) => {
            let _info = Object.assign({}, info);
            _info.clientes_mes = data.clientes_mes;
            _info.clientes_mes_p =  parseInt((data.clientes_mes*100) / (data.clientes_mes+data.clientes_old));
            _info.clientes_total = data.clientes_total;
            _info.produtos_ativos = data.produtos_ativos;
            _info.produtos_inativos = data.produtos_inativos;
            setInfo(_info);

            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        });
    }
    //#endregion Loaders



    return (
        <>
            <div id="ResumeTop" className="row">
                <div className="col-lg-3" onClick={() => LoadResumeTop(true)} style={{cursor:'pointer'}}>
                    <div className="ibox ">
                        <div className="ibox-title">
                            <div className="ibox-tools">
                                <span className="label label-success float-right">Mensal</span>
                            </div>
                            <h5>Clientes cadastrados</h5>
                        </div>
                        <div className="ibox-content">
                            <h1 className="no-margins">{info.clientes_mes}</h1>
                            {/*<div className="stat-percent font-bold text-success">{(info.clientes_mes_p)}% <i className={'fa ' + (info.clientes_mes>info.clientes_old?'fa-level-up':'fa-level-down')}></i></div>*/}
                            <small>Últimos 30 dias</small>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3" onClick={() => LoadResumeTop(true)} style={{cursor:'pointer'}}>
                    <div className="ibox ">
                        <div className="ibox-title">
                            <div className="ibox-tools">
                                <span className="label label-info float-right">Total</span>
                            </div>
                            <h5>Clientes cadastrados</h5>
                        </div>
                        <div className="ibox-content">
                            <h1 className="no-margins">{info.clientes_total}</h1>
                            <div className="stat-percent font-bold text-info"></div>
                            <small>Todo periodo</small>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3" onClick={() => LoadResumeTop(true)} style={{cursor:'pointer'}}>
                    <div className="ibox ">
                        <div className="ibox-title">
                            <div className="ibox-tools">
                                <span className="label label-primary float-right">Ativos</span>
                            </div>
                            <h5>Produtos cadastrados</h5>
                        </div>
                        <div className="ibox-content">
                            <h1 className="no-margins">{info.produtos_ativos}</h1>
                            <div className="stat-percent font-bold text-navy"></div>
                            <small>Todo periodo</small>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3" onClick={() => LoadResumeTop(true)} style={{cursor:'pointer'}}>
                    <div className="ibox ">
                        <div className="ibox-title">
                            <div className="ibox-tools">
                                <span className="label label-danger float-right">Inativos</span>
                            </div>
                            <h5>Produtos cadastrados</h5>
                        </div>
                        <div className="ibox-content">
                            <h1 className="no-margins">{info.produtos_inativos}</h1>
                            <div className="stat-percent font-bold text-danger"></div>
                            <small>Todo periodo</small>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


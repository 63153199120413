import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Request } from '../../../../scripts/Request';
import { Context } from '../../../../Context/AuthContext';
import { Currency } from '../../../../scripts/StringUtils';
import { LinkContainer } from 'react-router-bootstrap';
import './index.css';


export default function ResumeClients() {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);

    const _userRef = useRef();
    const timerIdleRef = useRef(new Date());
    const loadedRef = useRef(false);

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);


      //#region Effects
      useEffect(() => {
        let timer = setTimeout(() => { Init() }, 200);

        return () => {
            clearTimeout(timer);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 200) {
                try {
                    //HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


        useEffect(() => {
            if(isLoading){
                window.w_loadObj("#ResumeClients");
            } else {
                window.w_unloadObj("#ResumeClients");
            }
        }, [isLoading]);

        //#endregion Effects


    //#region Init
    const Init = () => {
        if(!loadedRef.current) {
            loadedRef.current = true;

            let wait_token = setInterval(()=> {
                if(user.token !== "")  {
                    clearInterval(wait_token);
                    LoadResumeClients();
                }
            }, 100);
        }
    }
    //#endregion Init



    //#region Loaders
    const LoadResumeClients = (force = false) => {
        setIsLoading(true);

        Promise.resolve(new Request().Run("api/Home/ResumeClients", "POST", {force: force}, user.token, 0*1000))
        .then((data) => {
            setList(data);

            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        });
    }
    //#endregion Loaders


    //#region Handlers
    const _DisableUser = async(item) => {
        window.swal({
            title: "Tem certeza?",
            text: "Deseja realmente " + (item.ativo===0?'ativar':'desativar') +" esse cliente?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Sim, tenho certeza!',
            cancelButtonText: "Não, cancelar!",
            closeOnConfirm: true,
            closeOnCancel: true
         },
         function(isConfirm){
            if (isConfirm){
                setIsLoading(true);
                Promise.resolve(new Request().Run("api/Clientes/Ativo", "POST", { cliente_id: item.cliente_id, ativo: (item.ativo===0?1:0) }, user.token))
                        .then((data) => {
                            LoadResumeClients(true);
                            setIsLoading(false);
                        }).catch(()=> {
                            setIsLoading(false);
                        });
            } else {
               
            }
         }); 
    }
    //#endregion Handlers



    return (

        <div id="ResumeClients" className="col-lg-6">
            <div className="ibox ">
                <div className="ibox-title">
                    <h5>Clientes aguardando liberação</h5> &nbsp;
                    <span className="label label-danger" onClick={() => LoadResumeClients(true)} style={{cursor:'pointer'}}>Atualizar</span>
                </div>
                <div className="ibox-content table-responsive">
                    <table className="table table-hover no-margins">
                        <thead>
                        <tr>
                            <th>Nome</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                            { list.length > 0 ? (
                                list.map((item, i) => (
                                    <tr key={'ita'+i}>
                                        <td><LinkContainer to={'/Clientes/Cadastro/' + item.cliente_id}><a href="#">{item.nome}</a></LinkContainer></td>
                                        <td style={{ padding: '4px', margin: 0, width: '75px' }}><button className="btn btn-white btn-sm" type="button" onClick={() => _DisableUser(item)} style={{ margin: '1px', padding: '1px', paddingLeft: '7px', paddingRight: '7px' }}>{(item.ativo===1?'Inativar':'Ativar')}</button></td>
                                    </tr>
                                ))
                            ) : (<></>)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
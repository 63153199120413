import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Request } from '../../../../scripts/Request';
import { Context } from '../../../../Context/AuthContext';
import { Currency } from '../../../../scripts/StringUtils';
import './index.css';


export default function ResumeAtividades() {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);

    const _userRef = useRef();
    const timerIdleRef = useRef(new Date());
    const loadedRef = useRef(false);

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);


      //#region Effects
      useEffect(() => {
        let timer = setTimeout(() => { Init() }, 200);

        return () => {
            clearTimeout(timer);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 200) {
                try {
                    //HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


        useEffect(() => {
            if(isLoading){
                window.w_loadObj("#ResumeAtividades");
            } else {
                window.w_unloadObj("#ResumeAtividades");
            }
        }, [isLoading]);

        //#endregion Effects


    //#region Init
    const Init = () => {
        if(!loadedRef.current) {
            loadedRef.current = true;

            let wait_token = setInterval(()=> {
                if(user.token !== "")  {
                    clearInterval(wait_token);
                    LoadResumeAtividades();
                }
            }, 100);
        }
    }
    //#endregion Init



    //#region Loaders
    const LoadResumeAtividades = (force = false) => {
        setIsLoading(true);

        Promise.resolve(new Request().Run("api/Home/ResumeAtividades", "POST", {force: force}, user.token, 0*1000))
        .then((data) => {
            setList(data);

            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        });
    }
    //#endregion Loaders


        return (

            <div id="ResumeAtividades" className="col-lg-6">
                <div className="ibox ">
                    <div className="ibox-title">
                        <h5>Clientes mais ativos (<small>nº de logins</small>)</h5> &nbsp;
                        <span className="label label-info" onClick={() => LoadResumeAtividades(true)} style={{cursor:'pointer'}}>Atualizar</span>
                    </div>
                    <div className="ibox-content table-responsive">
                        <table className="table table-hover no-margins">
                            <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Logins</th>
                            </tr>
                            </thead>
                            <tbody>
                                { Array.isArray(list) && list.length > 0  &&  list.map((item, i) => (
                                        <tr key={'ita'+i}>
                                            <td>{item.nome}</td>
                                            <td>{item.logins}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
    )
}
import React, { useState, useEffect, useContext, useRef, lazy } from 'react';
import { useLocation } from "react-router-dom";

import { Context } from './Context/AuthContext';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import NavMenu from './components/NavMenu/NavMenu';



const Logged = ({children}) => {
    const countRenders = useRef(0);

    return (
        <div id="wrapper">            
            <nav className="navbar-default navbar-static-side" role="navigation">
                <NavMenu />
            </nav>

            <div id="page-wrapper" className="gray-bg dashboard-1">
                <div className="row border-bottom">
                    <Header />
                </div>
                <div className="wrapper wrapper-content">
                    {children}
                </div>
                <div className="footer">
                    <Footer />
                </div>
            </div>
        </div>
    );
};


export default function Layout({ children }) {
    const [isLogin, setIsLogin] = useState(true);
    const location = useLocation();
    const { loading, authenticated } = useContext(Context);
    const pathRef = useRef("");
     //#region Effects
     useEffect(() => {
        pathRef.current = location.pathname;
        setIsLogin(location.pathname.toLocaleLowerCase().indexOf("login") > -1 || location.pathname === "/");
    }, [location.pathname]);

    //#endregion Effects

    return (
        <React.Fragment>
            {
                !isLogin && authenticated ? ( <Logged>{children}</Logged> ) : (<div>{children}</div>)
            }
           
        </React.Fragment>
    )
}
import React, { useState, useContext, useEffect } from 'react';
import { TimeAgo } from '../../scripts/DateTimeFilter';
import { Context } from '../../Context/AuthContext';



export default function Footer() {
    const { user, loading } = useContext(Context);
    let last = TimeAgo(user.lastAccess);





    return (
        <div className="footer-z">
            <div className="pull-right">
                <span className="txt-color-blueLight hidden-mobile">Último login do sistema <i className="fa fa-clock-o"></i> {last}<strong></strong> </span>
            </div>
            <div>
                <strong>Copyright</strong> ComercialWN &copy; 2022 - Recomendado com Google Chrome &nbsp; - &nbsp;
            </div>
        </div>
    );
}

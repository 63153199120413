import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { Context } from '../../../Context/AuthContext'
import $ from 'jquery';
import { Button, Modal } from "react-bootstrap";
import { Request } from '../../../scripts/Request';
import { FormGenerator } from '../../Form/FormGenerator';
import { useNavigate, useParams } from 'react-router-dom';
import { UploadService } from '../../../scripts/UploadService';
import { FilterObject } from '../../../scripts/ArrayUtils';
import './Cadastro.css';


export const Cadastro = (props) => {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [clienteId, setClienteId] = useState("");

    const _formClienteRef = useRef();
    const _formLoadedRef = useRef();
    const _clienteIdRef = useRef();
    const _clienteDataRef = useRef();
    const _userRef = useRef(user);

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    let { ClienteId } = useParams();


    //#region Effects
      useEffect(() => {
        if (ClienteId) {
            _clienteIdRef.current = ClienteId;
            setClienteId(ClienteId);
        }

        _userRef.current = user;
    }, [user]);


    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);

    //#endregion Effects


    //#region Form Handlers
    /*const on_uf_codigo = async (data) => {
        if ((_clienteIdRef.current == "" || _clienteIdRef.current == null || _clienteIdRef.current == undefined) && data.length > 0) {
            await _formClienteRef.current.Select("cidade_codigo", [], "api/Clientes/Cidades?uf_codigo=" + data[0].id, "GET", { }, _userRef.current.token);
        }
    }*/

    const uf_codigo = async (value) => {
        setPageLoading(true);
        await _formClienteRef.current.Select("cidade_codigo", [], "api/Clientes/Cidades?uf_codigo=" + value, "GET", { }, "");
        setPageLoading(false);
    }


    /*const on_cidade_codigo = async (data) => {
        if ((_clienteIdRef.current == "" || _clienteIdRef.current == null || _clienteIdRef.current == undefined) && data.length > 0) {
            await _formClienteRef.current.Value("cidade_codigo", _clienteDataRef.current.cidade_codigo);
        }
    }*/

    const OnFormLoading = () => {
        setPageLoading(true);
    }

    const OnFormLoaded = (fields) => {
        try {
            let wait_token = setInterval(async () => {
                if (_userRef.current.token !== "" && _formClienteRef.current !== undefined) {
                    clearInterval(wait_token);

                    await _formClienteRef.current.Select("uf_codigo", [], "/json/estados.json", "GET", "", "");
                    _formClienteRef.current.Value("uf_codigo", 26);
                    await _formClienteRef.current.Select("cidade_codigo", [], "api/Clientes/Cidades?uf_codigo=26", "GET", { }, _userRef.current.token);
                    let arr = _formClienteRef.current.GetOptions("cidade_codigo");
                    _formClienteRef.current.Value("cidade_codigo", arr[0].id);
                   
                    
                    if (_clienteIdRef.current !== "" && _clienteIdRef.current !== undefined && _clienteIdRef.current != null) {
                        let prod_data = await new Request().Run("api/Clientes/Load?cliente_id=" + _clienteIdRef.current, "POST", {}, _userRef.current.token);
                        _clienteDataRef.current = prod_data;
                        await _formClienteRef.current.Load(prod_data);
                        await _formClienteRef.current.Value("cidade_codigo", prod_data.cidade_codigo);
                       
                    } 
                    setPageLoading(false);
                }
            }, 100);
        } catch (e) {
            console.log(e);
        }

    }


    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers




    return (
        <div className="ClientesCadastro">
            <div>
                <form name="fuser" autoComplete="off">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <h5>Informações do Cliente</h5>
                                </div>
                               

                                <div className="ibox-content">
                                    <div className="row m-b-lg btngrp">
                                        <FormGenerator ref={_formClienteRef} name={'cliente'} url={'/forms/cliente.json'} execRef={Exec}></FormGenerator>
                                        <br />
                                      
                                        
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { Context } from '../../../Context/AuthContext'
import $ from 'jquery';
import { Button, Modal } from "react-bootstrap";
import { Request } from '../../../scripts/Request';
import { FormGenerator } from '../../Form/FormGenerator';
import { useNavigate, useParams } from 'react-router-dom';
import { UploadService } from '../../../scripts/UploadService';
import { FilterObject } from '../../../scripts/ArrayUtils';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import './Cadastro.css';


import { MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';


export const Cadastro = (props) => {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [produtoId, setProdutoId] = useState("");
    const [selectedImage, setSelectedImage] = useState("");
    const [showAddComboModal, setShowAddComboModal] = useState(false);
    const [showFotoEditModal, setShowFotoEditModal] = useState(false);
    const [AddComboTitle, SetAddComboTitle] = useState(false);
    const [AddComboInfo, SetAddComboInfo] = useState({ table: "", id_field: "", name_field: "" });
    const [AddComboList, SetAddComboList] = useState([]);
    const [imageList, setImageList] = useState([]);
    const navigate = useNavigate();

    const hiddenFileInput = useRef(null);
    const produtoFileInput = useRef(null);
    const _formProdutoRef = useRef();
    const _formLoadedRef = useRef();
    const _produtoIdRef = useRef();
    const _produtoDataRef = useRef();
    const _userRef = useRef(user);
    const _uploadedImages = useRef([]);

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);


    const [image, setImage] = useState("");
    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState();
    const onChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };
    

    let { ProdutoId } = useParams();


     //#region Effects
     useEffect(() => {
        if (ProdutoId) {
            _produtoIdRef.current = ProdutoId;
            setProdutoId(ProdutoId);
        }

        _userRef.current = user;
    }, [user]);


    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);

    //#endregion Effects


    //#region Form Handlers
    const OnFormLoaded = (fields) => {
        try {
            let wait_token = setInterval(async () => {
                if (_userRef.current.token !== "" && _formProdutoRef.current !== undefined) {
                    clearInterval(wait_token);
                    await _formProdutoRef.current.Select("categoria_id", [], "api/Produtos/Categorias", "POST", {}, _userRef.current.token, 10*1000);
                    await _formProdutoRef.current.Select("fabricante_id", [], "api/Produtos/Fabricantes", "POST", {}, _userRef.current.token, 10*1000);
                    
                    if (_produtoIdRef.current !== "" && _produtoIdRef.current !== undefined && _produtoIdRef.current != null) {
                        let prod_data = await new Request().Run("api/Produtos/Load?produto_id=" + _produtoIdRef.current, "POST", {}, _userRef.current.token);
                        _produtoDataRef.current = prod_data;
                        await _formProdutoRef.current.Load( prod_data);

                        _uploadedImages.current = prod_data.fotos;
                        setImageList(_uploadedImages.current);
                        /*if(prod_data.fotos.length > 0) {
                            prod_data.fotos.map((item, i) => {
                                _uploadedImages.current.push(item.foto);
                            });
                            setImageList(_uploadedImages.current);
                        }*/
                    } 
                }
            }, 100);
        } catch (e) {
            console.log(e);
        }

    }


    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();
        setPageLoading(true);
 

        let _result = await _formProdutoRef.current.Post("api/Produtos/Save", { produto_id: produtoId, fotos: _uploadedImages.current}, user.token);
        setPageLoading(false);

        if (_result.toString() === "exists") {
            alert("Nome já cadastrado!");
        } else {
            navigate('/Produtos/Lista');
        }
    }

    const _handlerUploadClick = () => {
        hiddenFileInput.current.click();
    }

    const _handlerUploadProdClick = () => {
        produtoFileInput.current.click();
    }


    const _HandleUpload = event => {
        UploadImages(event);
    }


    const UploadImages = async(event) => {
        setPageLoading(true);
        let upload = new UploadService();
        if(ProdutoId) upload.Data = {produto_id: ProdutoId};
        let c = 0;

        upload.OnEnd = (result) => {
            let arr = JSON.parse(result.img);
            //console.log(arr[0]);
            _uploadedImages.current.push({foto: arr[0], edited: 0});
            c++;

            if(c === event.target.files.length) {
                //console.log(_uploadedImages.current);
                setImageList(_uploadedImages.current);
                setPageLoading(false);
            }
        }

        for(let i=0; i<event.target.files.length; i++) {
            upload.Queue(event.target.files[i]);
        }

        await upload.PopUpload("https://painel.comercialwn.com.br/api/Produtos/UploadFotos", user.token);
    }


    const _handleDeleteFoto = async(foto) => {
        window.swal({
            title: "Tem certeza?",
            text: "Deseja realmente apagar essa foto?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Sim, tenho certeza!',
            cancelButtonText: "Não, cancelar!",
            closeOnConfirm: true,
            closeOnCancel: true
         },
         function(isConfirm){
            if (isConfirm){
                foto.foto = foto.foto.replace("https://painel.comercialwn.com.br/db/", "");
                setPageLoading(true);
        
                Promise.resolve(new Request().Run("api/Produtos/DeleteFoto", "POST", { foto: foto.foto }, user.token))
                    .then(async(data) => {
                        let ilist = [...imageList];
                        let index = ilist.findIndex(f => f.foto === foto.foto);
                        ilist.splice(index, 1);
                        _uploadedImages.current = ilist;
                        setImageList(ilist);
                        setPageLoading(false);
                    }).catch(() => {
                        setPageLoading(false);
                    });
            } else {
               
            }
         });


        
    }
    //#endregion Handlers


    //#region Form Handlers
     const new_categoria_id = async(value) => {
        let p = prompt("Digite o nome da categoria");
        if (p != "" && p != null && p != undefined) {
            await Promise.resolve(new Request().Run("api/Produtos/SaveCategoria", "POST", { name: p }, user.token))
                .then(async(data) => {
                   await _formProdutoRef.current.Load({ id: data, name: p }, "categoria_id", data, "append");
                });
        }
    }
    

    const sup_categoria_id = async () => {
        _EditAddCombo("Categorias", "produtos_categorias", "categoria_id", "nome");
    }


    const new_fabricante_id = async(value) => {
        let p = prompt("Digite o nome do fabricante");
        if (p != "" && p != null && p != undefined) {
            await Promise.resolve(new Request().Run("api/Produtos/SaveFabricante", "POST", { name: p }, user.token))
                .then(async(data) => {
                   await _formProdutoRef.current.Load({ id: data, name: p }, "fabricante_id", data, "append");
                });
        }
    }
    
    const sup_fabricante_id = async () => {
        _EditAddCombo("Fabricante", "produtos_fabricantes", "fabricante_id", "nome");
    }
    //#endregion Form Handlers


    //#region AddCombo Handlers
    const _handleCloseFotoEditModal= async() => {
        setShowFotoEditModal(false);
    }


    const _handleCloseAddComboModal = async() => {
        let _table = Object.assign({}, AddComboInfo).table;

        setShowAddComboModal(false);
        
        if(_table === "produtos_categorias") {
            await _formProdutoRef.current.Select("categoria_id", [], "api/Produtos/Categorias", "POST", "", user.token);
        } else if(_table === "produtos_fabricantes"){
            await _formProdutoRef.current.Select("fabricante_id", [], "api/Produtos/Fabricantes", "POST", "", user.token);
        }
    }

    const _handleSaveAddComboModal = () => {
        
    }


    const _EditAddCombo = (title, table, id_field, name_field) => {
        SetAddComboTitle(title);
        SetAddComboInfo({ table: table, id_field: id_field, name_field: name_field });
        setShowAddComboModal(true);

       
        Promise.resolve(new Request().Run("api/Produtos/AddComboList", "POST", { table: table, id_field: id_field, name_field: name_field }, user.token))
            .then((data) => {
                SetAddComboList(data);
            });
    }


    const _AddComboBlur = (item) => {
        let _data = Object.assign(AddComboInfo, { id: item.id, nome: item.name });
        Promise.resolve(new Request().Run("api/Produtos/AddComboUpdate", "POST", _data, user.token))
            .then((data) => {
                let _list = [...AddComboList];
                let _listItem = _list.find(l => l.id == item.id);
                _listItem = item;
                SetAddComboList(_list);
            });
    }


    const _DeleteAddCombo = (item) => {
        let _data = Object.assign(AddComboInfo, { id: item.id });
        Promise.resolve(new Request().Run("api/Produtos/AddComboDelete", "POST", _data, user.token))
            .then((data) => {
                let _list = [...AddComboList];
                let _listIndex = _list.findIndex(l => l.id == item.id);
               _list.splice(_listIndex, 1);
                SetAddComboList(_list);
            });
    }


    const _AddComboFieldChange = (item, event) => {
        let _list = [...AddComboList];
        let _listItem = _list.find(l => l.id == item.id);
        _listItem.name = event.target.value;
        SetAddComboList(_list);
    }
    //#endregion AddCombo Handlers


    //#region Cropper
    const _handleEditFoto = (foto) => {
        setShowFotoEditModal(true);
        setSelectedImage(foto);

        let request = new XMLHttpRequest();
        request.open('GET', "https://painel.comercialwn.com.br/db/"+foto.foto, true);
        request.responseType = 'blob';
        request.onload = function() {
            
            let reader = new FileReader();
            reader.onload =  function(e){
                console.log(e.target.result)
                setImage(e.target.result);
            };
            reader.readAsDataURL(request.response);
        };
        request.send();
    }

    const getCropData = (foto) => {
        if (typeof cropper !== "undefined") {
            foto = foto.replace("https://painel.comercialwn.com.br/db/", "");

            let ilist = [...imageList];
            ilist.find(f => f.foto === foto).edited = 1;

            setPageLoading(true);

            let cropped = cropper.getCroppedCanvas({ 
                width: 500,
                height: 500,
                minWidth: 500,
                minHeight: 500,
                maxWidth: 500,
                maxHeight: 500,
                fillColor: '#fff',
                imageSmoothingEnabled: true,
                imageSmoothingQuality: 'high'});
            setCropData(cropper.getCroppedCanvas().toDataURL());

        
            let blob = cropped.toBlob(async function (blob) {
                let file = new File([blob], foto, { type: 'image/jpeg' });
                let upload = new UploadService();
                upload.Data = {foto: foto}
                upload.OnEnd = (image) => {
                    setShowFotoEditModal(false);
                    setPageLoading(false);
                    forceUpdate();
                }
                await upload.Upload(file, "https://painel.comercialwn.com.br/api/Produtos/Crop", user.token);
            }, 'image/jpeg');
        }
    }
    //#endregion Cropper


    //#region Functions
    const getRand = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    //#endregion Functions

    return (
        <div className="ProdutosCadastro">
            <div>
                <form name="fuser" onSubmit={(event) => _HandleSubmit('produto', event)} autoComplete="off">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <h5>Cadastro de Produto</h5>
                                </div>
                                <div className="ibox-content ibox-heading">
                                    <small><i className="fa fa-stack-exchange"></i> Campos com * são obrigatórios. </small>
                                </div>

                                <div className="ibox-content">
                                    <div className="row m-b-lg btngrp">
                                        <FormGenerator ref={_formProdutoRef} name={'produto'} url={'/forms/produto.json'} execRef={Exec}></FormGenerator>
                                        <br />
                                      
                                        <div style={{ paddingTop: '10px', paddingLeft:'5px' }}>
                                            <button className="btn btn-primary btn-sm" title="Salvar Produto" type="submit" ><i className="fa fa-check"></i> Salvar Produto</button>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>


                        <div className="col-lg-4">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <h5>Lista de Fotos</h5>
                                </div>
                                <div className="ibox-content">
                                    <input type="file" ref={hiddenFileInput} onChange={_HandleUpload} style={{display: 'none'}} multiple accept=".jpg,.png"/>
                                    <button className={'btn btn-primary btn-sm' + (ProdutoId?'':' _hide')} type="button" onClick={_handlerUploadClick}><i className="fa fa-upload"></i> Enviar Fotos</button>
                                    <div className="images-container">
                                        {
                                            imageList.length > 0 ? (
                                                imageList.map((item, i) => (
                                                    <div key={'foto'+i} className="foto">
                                                        <img src={"https://painel.comercialwn.com.br/db/"+item.foto+"?"+getRand(0, 1000000)} alt="" />
                                                        <center>
                                                            <span className={item.edited===0?'':'hide'}><a onClick={() => _handleEditFoto(item)}>Editar</a> / <a onClick={() => _handleDeleteFoto(item)}>Apagar</a></span>
                                                            <span className={item.edited===0?'hide':''}>Editado / <a onClick={() => _handleDeleteFoto(item)}>Apagar</a></span>
                                                        </center>
                                                    </div>
                                                ))
                                            ) 
                                            : (<><center>Nenhuma foto enviada</center></>)
                                        }
                                       
                                    </div>
                                    
                                    <small>Fotos não editadas serão removidas após 24h</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <MDBModal show={showAddComboModal} setShow={setShowAddComboModal} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle><h4>{AddComboTitle}</h4></MDBModalTitle>
                        <Button className='btn btn-primary' onClick={_handleCloseAddComboModal} style={{width:'25px',height:'25px', padding:0}}>x</Button>
                    </MDBModalHeader>
                    <MDBModalBody>
                    {AddComboList.length > 0 ? (<div>
                        <table className="table table-striped table-hover">
                            <tbody>
                                {AddComboList.map((item, i) => (
                                    <tr key={'addc'+i}>
                                        <td style={{ padding: 0 }}> <input type="text" className="form-control inputfull" value={item.name} onChange={(event) => _AddComboFieldChange(item, event)} onBlur={() => _AddComboBlur(item)} /></td>
                                        <td style={{ padding: '4px', margin: 0, width: '35px' }}><button className="btn btn-white btn-sm" type="button" onClick={() => _DeleteAddCombo(item)} style={{margin:'1px',padding:'1px',paddingLeft:'7px',paddingRight:'7px'}}><i className="fa fa-trash"></i></button></td>
                                    </tr>
                                 ))}
                            </tbody>
                        </table>
                        </div>) : (<div>Nenhum dado Encontrado</div>)}
                    </MDBModalBody>

                    <MDBModalFooter>
                        <Button variant="secondary" onClick={_handleCloseAddComboModal}>
                            Fechar
                            </Button>
                    </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>


            <MDBModal show={showFotoEditModal} setShow={setShowFotoEditModal} tabIndex='-1'>
                <MDBModalDialog size='fullscreen'>
                    <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle><h4>Editar Imagem do produto</h4> </MDBModalTitle>
                        
                        <Button className='btn btn-primary' onClick={_handleCloseFotoEditModal} style={{width:'25px',height:'25px', padding:0}}>x</Button>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <div className="row">
                            <div className="col-md-8">
                                <Cropper
                                    style={{ height: 400, width: "100%" }}
                                    zoomTo={1}
                                    initialAspectRatio={1/1}
                                    aspectRatio={1/1}
                                    preview=".img-preview"
                                    src={image}
                                    scaleX={1}
                                    scaleY={1}
                                    viewMode={1}
                                    minCropBoxHeight={10}
                                    minCropBoxWidth={10}
                                    background={false}
                                    responsive={true}
                                    autoCropArea={1}
                                    checkOrientation={false}
                                    onInitialized={(instance) => {
                                        setCropper(instance);
                                    }}
                                    guides={true}
                                    />
                               
                            </div>
                            <div className="col-md-4">
                                
                                <div className="box" style={{ width: "250px", height: "250px" }}>
                                    <div id="foto" className="img-preview" style={{ width: "250px", height: "250px"}} />
                                    <canvas id="canvas" style={{ width: "250px", height: "250px", display:'none' }} width="250" height="250"></canvas>
                                </div>

                                 <hr />   
                                 <Button className="btn btn-primary" onClick={() => getCropData(selectedImage.foto)} style={{ width: '250px'}}> Salvar Recorte (500x500)</Button>
                                 <hr />   
                                 <input type="file" onChange={onChange} ref={produtoFileInput} style={{display: 'none'}} accept=".jpg,.png"/>
                                <Button className='btn btn-info' onClick={_handlerUploadProdClick} style={{width:'250px'}}>Enviar nova foto</Button>
                            </div>
                        </div>
                    </MDBModalBody>

                    <MDBModalFooter>
                       
                        <Button variant="secondary" onClick={_handleCloseFotoEditModal}>
                            Fechar
                        </Button>
                    </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
    );
}
import React, { useContext, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import $ from 'jquery';
import { Context } from '../../Context/AuthContext';
import './Login.css';



const Login = () => {
    const { loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [state, setState] = useState({
        login: "",
        password: ""
    });

    const loadedRef = useRef(false);

    //#region Effects
    useEffect(() => {
        if(!loadedRef.current) {
            loadedRef.current = true;
            handleLogout(true);
            $(".login_ex").hide();
            Init();
        }
    }, []);


    useEffect(() => {
       if(loading){
            window.w_loadObj(".plogin");
       } else {
            window.w_unloadAll();
       }
    }, [loading]);
    //#endregion Effects


     //#region Core
     const Init = () => {
        const _userpwd = localStorage.getItem('userpwd');

        if (_userpwd) {
            let _u = JSON.parse(_userpwd);
            setState(_u);
        }
    };
    //#endregion Core


    //#region Loaders
  
    //#endregion Loaders


    //#region Handlers
    const handleChange = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    }


    const _handleLogin = (event) => {
        event.preventDefault();
        const _state = Object.assign({}, state);
        const _userpwd = localStorage.getItem('userpwd');

        setState({ login: _state.login, password: _state.password });

        localStorage.setItem('userpwd', JSON.stringify({ login: _state.login, password: _state.password }));

        handleLogin(state);
    };

    const _HandlerEyeClick = (event) => {
        $(".field-icon").toggleClass("fa-eye-slash");
        var input = $("#password");
        if (input.attr("type") === "password") {
            input.attr("type", "text");
        } else {
            input.attr("type", "password");
        }
    }


    //#endregion Handlers



    return (
    <React.Fragment>
    <div className="Login">
        <div className="painels row" > 
            <div id="presents" className="login-pad col col-lg-4">
              
            </div>


            <div id="login" className="login-pad col col-lg-4">
                <div className="text-center animated fadeInDown vertical-center">
                    <div className="painel plogin">
                        <div>
                            <div className="logo">
                                <img src="/img/logo.png" />
                            </div>
                        </div>
                        <form className="m-t" role="form">
                            <div className="form-group">
                                <input id="login" name="login" type="text" className="form-control" placeholder="e-mail" required="required" value={state.login} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <input id="password" name="password" type="password" className="form-control" placeholder="senha" required="required" value={state.password} onChange={handleChange} />
                                <span className="fa fa-fw fa-eye field-icon" onClick={_HandlerEyeClick}></span>
                            </div>
                            <button type="button" className="btn block full-width m-b" style={{ backgroundColor:'#033071', height:'40px', color:'#ffffff'}} onClick={_handleLogin}>Entrar</button>
                        </form>
                        <p className="m-t"> <small>ComercialWN &copy; 2022</small> </p> 
                        
                    </div>
                </div>
            </div>


            <div id="cadastro" className="login-pad col col-lg-4">
                
            </div>
        </div>


    

       
    </div>
    </React.Fragment>)
};

export default Login;